import React from 'react'
import { withRouter } from 'react-router-dom'

class CongressProgram extends React.Component {
    render() {
        return( 
            <div className="container">
                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>ČETVRTAK 20.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(Dječija preventivna)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(9:30-10:00) <i>Jelena Mandić:</i> <b>"Male oralno hirurške intervencije u dečjoj stomatološkoj praksi"</b></li>
                            <li>(10:00-10:30) <i>Mirjana Ivanović:</i> <b>„Moralnoinicijalna hipomineralizacija (MIH) - protokol zbrinjavanja"</b></li>
                            <li>(10:30-11:00) <i>Bojana Davidović:</i> <b>“Stomatološka zaštita trudnica – da li joj poklanjamo dovoljno pažnje?“</b> </li>
                            <li>
                                <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(11:00-11:30) Kafe pauza</p>
                            </li>
                            <li>(11:30-12:00) <i>Svjetlana Janković:</i> <b>"Ortodoncija u svakodnevnoj praksi dječijeg stomatologa"</b></li>
                            <li>(12:00-12:30) <i>Mirjana Đuričković:</i> <b>"Oralno zdravlje kod djece sportista: Prevencija kao ključ uspjeha"</b></li>
                            <li>(13:00-13:30) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(13:30-14:00) Kafe pauza</p>
                        <p style={{fontStyle: 'italic'}}>(ENDODONCIJA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(14:00-14:30) <i>Branislav Karadžić:</i> <b>"Endodontsko hirurška terapija apeksnih parodontitisa danas"</b></li>
                            <li>(14:30-15:00) <i>Jelena Krunić:</i> <b>""</b></li>
                            <li>(15:00-15:30) <i>Aleksandra Žuža:</i> <b>“Minimalno invazivna endodoncija : Paradigma savremene prakse“</b> </li>
                            <li>(15:30-16:00) <i>Stevan Kopanja:</i> <b>"1 cement-1 šansa"</b></li>
                            <li>(16:00-16:30) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(19:00) Svečano otvaranje</p>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(19:30) Koktel dobrodošlice</p>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>PETAK 21.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(ORTODONCIJA)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(9:30-10:00) <i>Ivana Šćepan:</i> <b>"Distalizacija umjesto ekstrakcije:primjeri iz svakodnevne prakse"</b></li>
                            <li>(10:00-10:30) <i>Nenad Nedeljković:</i> <b>„Savremeni pristup u ortodontskoj terapiji pomoću mini implantata”</b></li>
                            <li>(10:30-11:00) <i>Antonija Jurela:</i> <b>“Razvoj procesa proizvodnje i kliničke primjene mobilnih ortodontskih naprava inovativnom tehnologijom direktnog 3D printanja“</b> </li>
                            <li>(11:00-11:30) <i>Neda Stefanović:</i> <b>"Širenje gornje vilice – zašto, kada i kako"</b></li>
                            <li>(11:30-12:00) <b>Diskusija</b></li>
                            {/* <li>(12:00-12:30) <i>prof. Zoran Lazić</i><b>"Imedijatna ugradnja, gde smo danas?"</b></li> */}
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(12:30-13:30) Ručak</p>
                        <p style={{fontStyle: 'italic'}}>(PARODONTOLOGIJA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(13:30-14:00) <i>Nataša Nikolić Jakoba:</i> <b>"Savremeni pristup u terapiji recesija gingive"</b></li>
                            <li>(14:00-14:30) <i>Mia Rakić:</i> <b>"Implantna površina u epicentru dijagnostičkih, terapijskih i preventivnih strategija periimplantitisa"</b></li>
                            <li>(14:30-15:00) <i>Uroš Tomić:</i> <b>“Oralno-medicinske komplikacije kao posledica stomatoloških intervencija“</b> </li>
                            <li>(15:00-15:30) <i>Saša Cakić :</i> <b>"Oralne ulceracije-diferencijalno dijagnostički značaj"</b></li>
                            <li>(15:30-16:00) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(20:30) Svečana večera</p>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>SUBOTA 22.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(HIRURGIJA)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(09:30-10:00) <i>Vladimir S. Todorović:</i> <b>„Anatomski parametri od značaja za uspešnost terapije zubnim implantima"</b></li>
                            <li>(10:00-10:30) <i>Danijel Domić:</i> <b>"Imedijatna ugradnja implantata u estetskoj zoni: ključni faktori za dugoročnu uspešnost"</b></li>
                            <li>(10:30-11:00) <i>Marko Magić:</i> <b>“Upotreba digitalnih alata postizanje optimalne trodimenzionalne pozicije implantata"</b> </li>
                            <li>(11:00-11:30) <i>Goran Stojković i dr Miloš Milošević:</i> <b>"Rehabilitacija ekstemno atrofične maksile  primenom zigomatičnih implantata"</b></li>
                            <li>(11:30-12:00) <i>prof. Miroslav Andrić: </i><b>"Lečenje dentogenih infekcija u oralno-hirurškoj praksi"</b></li>
                            <li>(12:00-12:30) <i>prof. Zoran Lazić: </i><b>"Imedijatna ugradnja, gde smo danas?"</b></li>
                            <li>(12:30-13:00) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(13:00-14:00) Ručak</p>
                        <p style={{fontStyle: 'italic'}}>(PROTETIKA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(14:00-14:30) <i> Dejan Lisjak i Sloboda Laslo:</i> <b>""</b></li>
                            <li>(14:30-15:00) <i>Verena Nizić i Francesco Ditommaso:</i> <b>"Keramički viniri"</b>
                                {/* <ul> */}
                                    {/* <li><b>"Keramički viniri"</b></li> */}
                                    {/* <li><b>Tema o keramičkim ljuskama (vinirima) u trajanju oko 45 min</b></li> */}
                                    {/* <li><b>Timsko predavanje stomatolog - dentalni tehničar s temom “Važnost gnatologije kod potpunih rehabilitacija” u trajanju od 45-60 min zavisno o Vašim potrebama</b></li> */}
                                {/* </ul> */}
                            </li>
                            <li>(15:00-15:30) <i>Andrej i Leon Lazić:</i> <b>"ZrO2-detalji koji cine razliku"</b></li>
                            <li>(15:30-16:00) <i>Milena Kostić:</i> <b>“Budućnost je počela - kako do idealnog odnosa krunice i predela demarkacije preparacije“</b> </li>
                            <li>(16:00-16:30) <i>Marko Milićević:</i> <b>"Cerek protokoli 21. vijeka"</b></li>
                            <li>(16:30-17:00) <i>Adrijan Kasaj</i><b>"Treatment of gingival recession: When, why and how?"</b></li>
                            <li>(17:00-17:30) <b>Diskusija</b></li>
                            <p style={{fontStyle: 'italic', paddingTop:"10px"}}>(ITI Study Club Montenegro sastanak)</p>
                            <li>(17:30-17:45) <i>dr. Milan Vučetić, prof.dr Miroslav Andrić: </i><b>"Dobrodošli u ITI Study Club Montenegro"</b></li>
                            <li>(17:45-18:15) <i>dr. Tanja Živanović (Klinika za Parodontologiju i Oralnu medicinu, Stomatološki fakultet, Univerzitet u Beogradu, Srbija): </i><b>"Moja ITI stipendija - Sinergija kliničkog i istraživačkog rada tokom jednogodišnjeg boravka u Londonu"</b></li>
                            <li>(18:15-19:15) <i>Dr. Avish Jay Jagathpal (Department for Prosthodontics, Univeristy of Pretoria, South Africa): </i><b>"Aesthetic Consideration Around Implants"</b></li>
                        </ul>
                    </div>
                </div>

                {/* <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <ul >
                            <li><i>Adrijan Kasaj sa tehničarem</i> <b></b></li>
                            <li><i>Milan Vučetić</i> <b></b></li>
                            <li><i>Marko Milićević</i> <b></b></li>
                        </ul>
                        <p style={{fontStyle: 'italic'}}>(Predavanje tehničari)</p>
                        <ul >
                            <li><i>Leon i Andrej Lazić</i> <b></b></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default withRouter(CongressProgram)