import React from 'react'
import { withRouter } from 'react-router-dom'
import program from '../assets/img/program.png'
import kongres from '../assets/img/kongres.png'
import prijava from '../assets/img/prijava.png'
import { userPath } from '../constants/user.constants'


class Congress extends React.Component {

    showRegistrationPage = () => {
        let path = userPath.registration
        this.props.history.push(path)
    }

    showCongressDetailsPage = () => {
        let path = userPath.congressDetails
        this.props.history.push(path)
    }

    showCongressProgramDetailsPage = () => {
        let path = userPath.congressProgram
        this.props.history.push(path)
    }

    render() {
        return( 
            <div className="container">
                <div className="row" style={{paddingTop:'10px'}}>
                    <div className="col-sm-4 congress" onClick={this.showRegistrationPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={prijava} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Prijava
                                </h5>
                                    <p>Prijavu za Kongres možete izvršiti klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 congress" onClick={this.showCongressDetailsPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={kongres} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Kongres
                                </h5>
                                <p>Više informacija o Kongresu možete pogledati klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 congress" onClick={this.showCongressProgramDetailsPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={program} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Program
                                </h5>
                                <p>Više informacija o programu Kongresu možete pogledati klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%'}}>
                    <div className="row" style={{paddingLeft:'5px', paddingRight:'5px'}}>
                        <div className="col-md-10 col-md-offset-1" >
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Rana kotizacija do 01.03.2025.godine:</p>
                            <ul style={{padding:'10px'}}>
                                <li>Za članove Komore - 150,00€</li>
                                <li>Učesnici koji nisu članovi Komore - 200,00€</li>
                            </ul>
                        
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Kasna kotizacija do 15.03.2025.godine:</p>
                            <ul style={{paddingTop:'10px'}}>
                                <li>Za članove Komore - 200,00€</li>
                                <li>Učesnici koji nisu članovi Komore - 250,00€</li>
                            </ul>
                            <ul style={{paddingTop:'10px'}}>
                                <li>Kotizacija za tehničare - 100,00€</li>
                            </ul>
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>*U okviru kotizacije uključeni su: ručak i kafe pauza u petak i subotu (21. i 22. mart 2025 godine).</p>

                            <div style={{paddingTop:'10px', paddingBottom:'15px'}}>
                                <p>
                                    Uplate za kotizaciju možete izvršiti na 
                                    žiro račun : 510-200340-32 CKB Banka 
                                    IBAN : ME25510000000021117619
                                </p>
                                <p>Gotovinsko plaćanje prilikom registracije nije moguće.</p>
                                <p>Pozivamo vas da i vi uzmete aktivno učešće prezentacijom naučnih radova i prikaza iz prakse i na taj način obogatite program edukacija.</p>
                            </div>


                            <p style={{fontWeight:'bold', fontStyle: 'italic', paddingBottom:'10px'}}>Rok za prijem rezimea: 01.03.2025</p>

                            <p style={{fontWeight:'bold', fontStyle: 'italic', paddingBottom:'10px', color:'darkred'}}>***Važno obavještenje: Za prijavu rezimea se koristi ista forma kao za prijavu učesnika kogresa (rad je neophodno uploadovati u poslednoj koloni "Rad")</p>

                            <p>Uz navedeni sažetak obavezno navesti način prezentacije:</p>
                            <ul style={{padding:'10px'}}>
                                <li>Oralna prezentacija (5 min) ili</li>
                                <li>Poster prezentacija (dimenzije postera 60cm x 90cm(širina/visina)). Dostaviti dokaz o uplati kotizacije - Prihvaćeni radovi bez dokaza o uplati kotizacije neće biti objavljeni u zborniku radova.</li>
                                <li>Rezimei podlažu recenziji</li>
                                <li>Autori će biti obavješteni o odluci Naučnog odbora</li>
                            </ul>
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Učešće na Kogresu se boduje prema pravilniku Stomatološke Komore Crne Gore</p>
                            <ul style={{padding:'10px'}}>
                                <li>Pasivno učešće: 12 bodova</li>
                                <li>Aktivno učešće (prvi autor): 20 bodova</li>
                                <li>Drugi autor: 15 bodova</li>
                                <li>Predavač: 25 bodova</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Congress)